import React from "react";
import mike from "./assets/mike.jpg";
import "./App.css";
import {
  Avatar,
  WithStyles,
  withStyles,
  Typography,
  Link,
} from "@material-ui/core";

const styles = {
  bigAvatar: {
    margin: 10,
    width: 130,
    height: 130,
  },
};
const App = ({ classes }: WithStyles) => (
  <div className="App">
    <Avatar src={mike} alt="Mike Lowe" className={classes.bigAvatar} />
    <Typography variant="h6">
      <Link href="http://resume.mikelowe.ca" color="inherit">
        Resume
      </Link>
    </Typography>
  </div>
);
export default withStyles(styles)(App);
